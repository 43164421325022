@if ($title() && !isPrescreenEnabled()) {
  <h2 class="!font-gt-walsheim !mb-6 !text-2xl !font-medium !leading-none" [innerHtml]="$title()"></h2>
}

<form [formGroup]="form" class="search-new-form mb-6">
  <myt-input
    [control]="form.controls.condition"
    [label]="'SEARCH.CONDITION_LABEL' | translate"
    [placeholder]="'SEARCH.CONDITION_PLACEHOLDER' | translate"
    [type]="inputType.Text"
    [autocomplete]="conditionAutocomplete.autocomplete()!"
    [customValidationMessages]="form.controls.condition.invalid ? { requireEither: 'SEARCH.REQUIRED_EITHER' } : undefined"
    (focusIn)="removeValidator()"
    (blur)="onConditionFieldBlur()"
  >
    <myt-autocomplete
      #conditionAutocomplete
      [control]="form.controls.condition"
      [options]="searchNewService.$conditionOptions()"
      [loading]="searchNewService.$loading()"
      (valueChanges)="searchNewService.onConditionValueChanges($event)"
      (selectOption)="onSelectCondition($event)"
    />
  </myt-input>

  @if ($isAliasesLoading()) {
    <myt-spinner />
  } @else if (form.controls.conditionAliases.length) {
    <div class="mb-6 flex flex-col">
      <small class="text-muted text-muted--sm">{{ 'SEARCH.SYNONYMS_LABEL' | translate }}</small>
      @for (synonymControl of form.controls.conditionAliases.controls; track synonymControl) {
        <myt-checkbox [control]="synonymControl.controls.checked" [text]="synonymControl.controls.label.value || ''" />
      }
    </div>
  }

  <div class="mb-3">
    <myt-location [$radiusForm]="form.controls.radius" [$countryForm]="form.controls.country" />
  </div>

  <myt-input
    [control]="form.controls.otherTerms"
    [label]="'SEARCH.OTHER_TERMS_LABEL' | translate"
    [hint]="'SEARCH.OTHER_TERMS_HINT' | translate"
    [placeholder]="'SEARCH.OTHER_TERMS_PLACEHOLDER' | translate"
    [customValidationMessages]="form.controls.otherTerms.invalid ? { requireEither: 'SEARCH.REQUIRED_EITHER' } : undefined"
    (focusIn)="removeValidator()"
  />
  @if (!isPrescreenEnabled()) {
    <myt-button
      [type]="buttonType.Flat"
      [buttonNativeType]="buttonNativeType.Submit"
      class="float-right mt-10"
      (buttonClick)="startNewSearch()"
    >
      {{ 'SEARCH.START_SEARCH_BTN' | translate }}
    </myt-button>
  } @else {
    @if (enableTrialMatch()) {
      <div id="initiate-trial-form-medical-summary-container">
        <div class="mb-1 mt-4 flex flex-row justify-between">
          <label class="text-regular font-semibold" id="initiate-trial-form-medical-summary-label">{{
            'SEARCH.MEDICAL_SUMMARY_LABEL' | translate
          }}</label>
          <span
            role="button"
            class="text-regular text-accent-600 cursor-pointer font-normal"
            tabIndex="0"
            (click)="showMedicalSummaryExample()"
            >{{ 'SEARCH.MEDICAL_SUMMARY_EXAMPLE_BTN' | translate }}</span
          >
        </div>

        <myt-textarea
          [attr.id]="'initiate-trial-form-medical-summary-input'"
          [hint]="'SEARCH.MEDICAL_SUMMARY_HINT' | translate"
          [control]="form.controls.profile"
          [maxlength]="8000"
          [maxRow]="12"
          [rows]="3"
          [customValidationMessages]="{ required: 'SEARCH.MEDICAL_SUMMARY_VALIDATION' }"
          ariaLabelledBy="initiate-trial-form-medical-summary-label"
        />
      </div>
    }

    <div class="mt-10 flex flex-col justify-between gap-3 md:flex-row-reverse md:gap-4" [ngClass]="{ 'justify-end': !enableTrialMatch() }">
      @if (enableTrialMatch()) {
        <myt-button
          [type]="buttonType.Flat"
          [customCssClasses]="enableTrialMatch() ? 'w-full' : ''"
          class="search-new-form__find-matches-button w-full"
          (buttonClick)="startNewSearch(true)"
        >
          <myt-icon name="ai" [size]="iconSize.Small" class="mr-2" />
          <span>{{ 'SEARCH.FIND_MATCHES_BTN' | translate }}</span>
        </myt-button>
      }
      <myt-button
        customCssClasses="w-full"
        [type]="enableTrialMatch() ? buttonType.Stroked : buttonType.Flat"
        class="{{ enableTrialMatch() ? 'w-full' : 'ml-auto' }}"
        (buttonClick)="startNewSearch()"
      >
        <myt-icon name="search" [size]="iconSize.Small" class="mr-2" />
        <span>{{ 'SEARCH.START_SEARCH_BTN' | translate }}</span>
      </myt-button>
    </div>
  }
</form>
